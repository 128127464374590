import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { ApiResponse } from '@core/models/api-response.model'
import { ApiService } from '@core/services/api.service'
import { environment } from '@environment/environment'
import { Observable } from 'rxjs'
import { ReleaseNote, ReleaseNoteDto } from '../models/release-note.model'

@Injectable({
    providedIn: 'root',
})
export class ReleaseNoteApiService extends ApiService<ReleaseNoteDto, ReleaseNote> {
    protected http: HttpClient

    private baseUrl = environment.BASE_URL

    constructor() {
        const http = inject(HttpClient)

        super(http, 'v2/release-notes')

        this.http = http
    }

    getReleaseNotesForUser(userId: string): Observable<ApiResponse<ReleaseNote[]>> {
        const params = { sortBy: 'createdAt', orderBy: 'desc' }
        return this.http.get<ApiResponse<ReleaseNote[]>>(
            `${this.baseUrl}/v2/release-notes/for-user/${userId}`,
            { params },
        )
    }
}
