import { inject } from '@angular/core'
import { CanActivateFn } from '@angular/router'
import { AuthApiService } from '@modules/auth/services/auth-api.service'
import { AuthStateService } from '@modules/auth/states/auth-state.service'
import { catchError, map, of } from 'rxjs'

export function superAdminGuard(): CanActivateFn {
    return () => {
        const authApiService = inject(AuthApiService)
        const authStateService = inject(AuthStateService)
        return authApiService.isSuperAdmin(authStateService.getUserId()).pipe(
            map((response) => {
                return response.data
            }),
            catchError(() => {
                return of(false)
            }),
        )
    }
}
